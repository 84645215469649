<template>
  <v-app style="background-color: #f2f2f2">
   <v-overlay :value="processloader_Sync" z-index="2" color="black" dark>
      <v-progress-circular indeterminate class="ma-2" size="64" color="white"></v-progress-circular>
      <span :style="colorProgress">กำลัง Sync ข้อมูล...</span>
    </v-overlay>
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      processloader_Sync:false,
      fail_: false,
      errormessage: "",
      folder_taxbox:""
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
    colorProgress() {
    //return "color:" + this.color.theme + ";";
    return "color:" + "white" + ";";
  },
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      console.log("this.$route.query.accesstoken",this.$route.query.sharetoken);
      console.log("id",this.$route.query.id);
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.sharetoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!"        
      } else {
        let _accesstoken = this.$route.query.sharetoken;

        let payload = {
          shared_token: _accesstoken,
        };
        if(this.$route.query.id){
          VueCookies.set('idc', this.$route.query.id);
          VueCookies.set('taxbox_check', true);
        }

        //localStorage.clear();
        let encodetoken = window.btoa(_accesstoken)
        //localStorage.setItem("token", encodetoken);
        // localStorage.setItem("token", _accesstoken);
        // ไม่ต้องสนใจเพราะว่า มีการ set token ใหม่ในหน้า Action authorize_login_business_sharedtoken
        VueCookies.set('token', _accesstoken);
        VueCookies.set('token_auth', true);
      

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch("authorize_login_business_sharedtoken", payload);
        console.log("check_auth",check_auth);
        console.log("check_auth.status",check_auth.status);
        if (check_auth.status === "Access Granted") {
          // ส่ง event ไปยัง Google Analytics
          this.$gtag.event('login', {
            event_category: "bypass_taxbox",
          });

          VueCookies.set("checklogin",true)
          console.log("เข้า");
         //เก่า // this.fn_getfoldertaxbox();
         this.loadSourceSystem();
        //  this.$router.push({ path: "/myinbox-system", query: { type: 1 } });

          // this.processloader_Sync = true;
          // VueCookies.set("checklogin", true, 60 * 60 * 24);
          // this.$router.push({path:'/mydrive'});
        } else {
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!"
        }
      }
    },
    async loadSourceSystem() {
      // this.loaddataprogress = true;
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid_userinfo"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      let payload = {
        receiver_id: user_id,
        receiver_type: "1"
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/getmyinbox",
          payload,
          { withCredentials: false ,
          headers: { Authorization: auth.code },}
        );
        // this.loaddataprogress = false;

        if (response.data.status === "OK") {
          console.log("response",response.data.result);

          let objIndex = response.data.result.findIndex((obj) => obj.system === "ETAX");
          console.log("objIndex",objIndex);
          if(objIndex !== -1){
            this.$router.push({ path: "/myinbox-sourcesystem", query: { type: 1 , sourcesystem:"ETAX" , systemid:response.data.result[objIndex].system_id} });
          }else{
            this.$router.push({ path: "/myinbox-system", query: { type: 1 }});
          }
          // this.systemdirectory = response.data.result;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage
          });
        }
      } catch (ex) {
        // this.loaddataprogress = false;
      }
    },
    async fn_getfoldertaxbox(){
      // this.loaddataprogress = true;
      this.processloader_Sync = true;
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      let payload = {
          oneid_user_id: user_id
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/folder_taxbox_system", payload, {
            headers: { Authorization: auth.code } 
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === "OK") {
              this.processloader_Sync = false;
          //   this.loaddataprogress = false;
            console.log("taxbox",response.data);
            this.folder_taxbox  = response.data.folder_id
            this.$router.push({ path: "/directory/" + this.folder_taxbox });
            VueCookies.set('taxbox', true);

          } else {
          //   this.loaddataprogress = false;
          this.processloader_Sync = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // this.loaddataprogress = false;
          this.processloader_Sync = false;
          console.log('err', error);
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
        });
    }
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  }
}
</script>
